<template>
  <div v-if="project && group">
    <Input class="mb15" tag="textarea" :class="{ 'green': equal }" v-model="sites" placeholder="Список сайтов для обхода" />
    <Btn @click="savesites" :disabled="equal" class="w100">Сохранить</Btn>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Input from './ui/Input.vue';
import Btn from './buttons/Btn.vue';

export default {
  components: { Input, Btn },
  data: () => ({
    sites: ''
  }),
  computed: {
    ...mapState(['url','project', 'group']),
    sitesval() {
      return this?.sites?.split('\n').map(el => el.trim())
    },
    equal() {
      if (!this.project || !this.group)
        return false
      if (this.group.sites.length != this.sitesval.length)
        return false
      for (let i = 0; i < this.group.sites.length; i++)
        if (this.group.sites[i] != this.sitesval[i])
          return false
      return true
    }
  },
  methods: {
    async savesites() {
      let sites = this.sitesval
      if (this.equal)
        return
      this.$store.commit('toggleLoad', 'savesites')
      await fetch(this.url + 'group', {
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: this.project?.name, group: this.group?.name, sites: sites })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        this.$store.dispatch('setGroup', this.group?.name)
      })
        .catch(err => { console.error(err); alert(err) })
      this.$store.commit('toggleLoad', 'savesites')
    }
  },
  watch: {
    group(v, old) {
      if (v != old) {
        console.log(v)
        if (v && v.sites && Array.isArray(v.sites))
          this.sites = v.sites.map(el => el.trim()).join('\n') || ''
        else
          this.sites = ''
      }
    }
  }
}
</script>

<style lang="sass" scoped>

</style>