<template>
  <LoadScreen />
  <router-view v-if="Role != null" v-slot="{ Component }">
    <transition name="fade" mode="ease-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import LoadScreen from './views/LoadScreen.vue'
import { mapState } from 'vuex'

export default {
  components: { LoadScreen },
  data: () => ({
    inited: false,
    interval: false
  }),
  async created() {
    let base = window.location.origin
    if (process.env.NODE_ENV == 'development') {
      console.log("DEV_MODE")
      base = base.replace('8080', '3260')
    }
    this.$store.commit('setUrl', base + '/')
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      fetch(this.url + 'busy', { credentials: 'include' })
        .then(async res=>{
          if (res.ok)
            this.$store.commit('setBusy', await res.json())
          else 
            throw(res.status)
        })
        .catch(err => console.error(err))
    }, 10000)
  },
  mounted() {
    this.$store.dispatch('checkAuth')
    document.head.querySelector('title').innerHTML = 'Links'
    setTimeout(() => {
      if (!this.inited)
        this.init()
    }, 100)
  },
  methods: {
    init() {
      this.inited = true
      if (this.Role) {
        this.$store.dispatch('updateProjects')
        this.$router.push('/app')
      }
      else
        this.$router.push('/login')
    }
  },
  computed: {
    ...mapState(['Role', 'project', 'url'])
  },
  watch: {
    project(v, old) {
      if (v != old) {
        this.$store.dispatch('updateGroups')
      }
    },
    Role() {
      this.init()
    }
  }
}
</script>

<style lang="sass">
.w100
  width: 100%
.mb10
  margin-bottom: 10px
.mb15
  margin-bottom: 15px
.mb20
  margin-bottom: 20px
.mb30
  margin-bottom: 30px
.apexcharts-tooltip.apexcharts-theme-light
  display:  grid !important
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title
  grid-column: span 2 !important
body
  *, *::after, *::before
    box-sizing: border-box
    font-family: 'Open Sans', sans-serif

.fade-enter-active, .fade-leave-active
  transition: opacity .4s ease
.fade-enter-from, .fade-leave-to
  opacity: 0
  user-select: none
body
  margin: 0
  padding: 0
  ul, li
    margin: 0
    padding: 0
    list-style-type: none
</style>
