<template>
  <div v-if="project">
    <Input class="mb15 w100" :class="{ 'green': equal && link }" v-model="link" placeholder="Какую ссылку ищем?" />
    <Btn class="w100" @click="saveLink" :disabled="equal">Сохранить</Btn>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Input from './ui/Input.vue';
import Btn from './buttons/Btn.vue';

export default {
  components: { Input, Btn },
  data: () => ({
    link: ''
  }),
  computed: {
    ...mapState(['url','project']),
    equal() {
      return this.link.trim() == ((this.project?.link || '')).trim()
    }
  },
  methods: {
    async saveLink() {
      let link = this.link.trim()
      if (this.equal)
        return
      if (this.project?.link && !confirm('Изменение ссылки удалит все отчёты в данном проекте. \nВы уверены?'))
        return
      this.$store.commit('toggleLoad', 'saveLink')
      await fetch(this.url + 'project', {
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: this.project?.name, link: link })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        this.$store.commit('setProject', { ...this.project, link: link })
      })
        .catch(err => { console.error(err); alert(err) })
      this.$store.commit('toggleLoad', 'saveLink')
    }
  },
  watch: {
    project(v,old) {
      if (!v != old)
        this.link = v?.link || ''
    }
  },
}
</script>

<style lang="sass" scoped>

</style>