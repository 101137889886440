<template>
  <div v-if="project && group && reports">
    <div class="tablezone">
      <Apexchart v-if="group && dates.length" width="750" type="line" :options="chartOptions()" :series="series()" />
      <div class="uptable-controls tablezone__controls">
        <Btn @click="makeReport">Создать отчёт</Btn>
        <Btn :small="true" v-if="group && (group.sites.length > limit)" @click.prevent.stop="limited = !limited" class="tablesave btn btn_blue">{{ limited ? 'Показать все' : `Показать ${limit}` }}</Btn>
        <!--
        <Btn :small="true" v-if="names && names.length" class="tablesave btn btn_blue" @click="save">Сохранить таблицу</Btn>
      -->
      </div>
      <div style="width: 100%; padding-top: 30px; margin-top: -30px;" v-if="dates && dates.length && limitedsites && limitedsites.length">
        <table class="table" ref="table" :data-cols-width="getColWidth ? getColWidth : ''">
          <thead>
            <tr>
              <th data-a-h="center" data-a-v="middle"><b>{{ 'URL' }}</b></th>
              <th class="datecell" data-a-h="center" data-a-v="middle" v-for="date, index in dates" :key="date" @click="toggleSort(date)">
                <b>{{ compdates[index] }}</b>
                <span v-if="sortDate === date" class="sortarrow" :class="{ 'sortarrow_reverse': sortReverse }">↓</span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="site in limitedsites" :key="site">
              <td class="link link_url" data-a-v="middle">
                <div class="link__message"></div>
                <div class="link__site">{{ site }}</div>
              </td>
              <td v-for="(date, index) in dates" :key="date" class="CELL" :data-a-h="'center'" data-a-v="middle" :data-t="'n'" :data-code="reports[index].data[site] ? reports[index].data[site][0] : ''" :class="reports[index].data[site] ? ('c' + reports[index].data[site][1]) : ''">
                <div class="CELL__TOOLTIP">{{ reports[index].data[site] ? reports[index].data[site][2] : '' }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Apexchart from 'vue3-apexcharts'
import { mapState, mapGetters } from 'vuex/dist/vuex.cjs.js';
import Btn from './buttons/Btn.vue';

export default {
  data: () => ({
    limited: true,
    limit: 500
  }),
  components: { Btn, Apexchart },
  computed: {
    ...mapState(['url', 'project', 'group', 'reports']),
    ...mapGetters(['dates']),
    compdates() {
      return this.dates.map(el => el.replace(/\.\d\d\d\d$/, ''))
    },
    limitedsites() {
      if (this.limited)
        return this.group.sites.slice(0, this.limit)
      else
        return this.group.sites
    },
  },
  methods: {
    async makeReport() {
      this.$store.commit('setBusy', true)
      let status
      await fetch(this.url + 'report', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: this.project?.name, group: this.group?.name })
      }).then(async res => {
        status = res.status
        if (status != 503)
          this.$store.commit('setBusy', false)
        if (!res.ok) throw (await res.text())
        this.$store.dispatch('updateReports')
      })
        .catch(err => {
          console.error(err);
          if (status != 503)
            this.$store.commit('setBusy', false)
          alert(err)
        })

    },
    series() {
      let res = []
      let names = ['найдено', 'найдено похожее', 'не найдено', 'ошибки']
      for (let name of names)
        res.push({ name, data: [] })

      for (let i = 0; i < this.dates.length; i++) {
        let data = Object.values(this.reports[i].data)
        for (let v of res)
          v.data.push(0)
        for (let value of data) {
          if (!value)
            continue
          switch(value[1]) {
            case 'BLACK':
            case 'ERR':
              res.find(el => el.name == 'ошибки').data[i]++
              break
            case 'NOTFOUND':
              res.find(el => el.name == 'не найдено').data[i]++
              break
            case 15:
            case '15':
            case 10:
            case '10':
              res.find(el => el.name == 'найдено похожее').data[i]++
              res.find(el => el.name == 'найдено похожее').data[i]++
              break
            case '20':
            case 20:
              res.find(el => el.name == 'найдено').data[i]++
              break
            default: 
            alert('Неизвестное значение status: '  + value[1])
          }          
        }
      }
      return res
    },
    transformseries(series) {
      let obj = {}
      for (let el of series)
        obj[el.name] = el.data
      return obj
    },
    selectColor(number) {
      const hue = number * 137.508
      return `hsl(${hue},50%,75%)`
    },
    getColors(number) {
      let a = []
      for (let i = 1; i <= number; i++)
        a.push(this.selectColor(i))
      return a
    },
    maxseries(series) {
      let res = 1
      for (let s of series)
        res = Math.max(res, ...s.data)
      return res
    },
    chartOptions() {
      return {
        chart: {
          animations: {
            enabled: false,
          },
          height: 400,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        colors: ['#aeffae','#ffe7a0','#f3b7bd','rgb(100, 100, 100)'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.compdates,
        },
        yaxis: {
          min: 0,
          max: this.maxseries(this.series())
        },
        title: {
          text: '',
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },
  }
}
</script>

<style lang="sass" scoped>
$danger: #dc3545
$success: #198754
$primary: #0d6efd
$lightdanger: lighten($danger, 30)

.CELL
  position: relative
  &:hover &__TOOLTIP
    display: block
  .CELL__TOOLTIP
    pointer-events: none
    text-align: left
    position: absolute
    top: 0%
    left: 100%
    min-height: 100%
    padding: 5px

    display: none
    &:empty
      display: none !important
    background: white
    border: 1px solid var(--color)
    box-shadow: 0 0 5px var(--color, black)
  &::before
    font-weight: 600
  &.cBLACK
    --color: black
    background: rgb(50,50,50) !important
  &.cERR
    --color: rgb(100,100,100)
    background: rgb(100,100,100) !important
    &::before
      color: white
      font-size: 12px
      content: attr(data-code)

  &.cNOTFOUND
    --color: #{$lightdanger}
    background: $lightdanger !important
    &::before
      content: '—'
.CELL.c15,.CELL.c10
  background: lighten(colors.$yellow, 40) !important
  &::before
    content: '+'
  .CELL__TOOLTIP
    --color: #{colors.$yellow}
    font-weight: 600
    &::before
      display: block
      font-weight: 500
      content: 'Найдена похожая ссылка: '
.CELL.c20
  background: colors.$ok !important
  &::before  
    content: '+'


.sortarrow
  line-height: 0
  color: colors.$blue
  font-weight: 900
  margin-left: 4px
  font-size: 20px
  display: inline-block
  &_reverse
    transform: scale(1,-1) translateY(-3px)
.uptable-controls
  display: flex
  gap: 20px

.tablezone
  &__controls
      margin-bottom: 20px
.table  
  display: block
  max-width: 100%
  font-size: 14px
  margin-bottom: 30px
  tbody td
    z-index: 50 !important
    &.green
      background: #e6ffe6 !important
    &.red
      background: #ffe6e6 !important
  tbody td:hover
    z-index: 51 !important
  thead
    position: sticky
    top: 0
    z-index: 4
    &::after
      content: ''
      background: white 
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      top: 0
      display: block
      z-index: -1
    td
      background: white
  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  td:first-child
    width: 300px
    min-width: 300px
    max-width: 300px
  tr
    font-weight: 500
    white-space: nowrap
  td.bad
    background: rgba(255,200,200) !important
  td.good
    background: rgb(200,255,200) !important
  td.active
    background: white !important
    position: relative
    padding-right: 15px
    &::after
      position: absolute
      content: '↑'
      right: 2px
      font-size: 18px
      top: 50%
      transform: translateY(-65%)
      transform-origin: center center
  .link__message:empty
    display: none !important
  .link__message:not(:empty) ~ *
    display: none !important
  td.filtered
    outline: 2px solid violet
    .datecell__count
      opacity: 1
      pointer-events: auto
  .inversed .active::after
    content: '↓'

  td
    padding: 3px 10px
    position: relative
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:not(:first-child)
      text-align: center
    &.group
      text-align: left
      width: 150px !important
      max-width: 150px !important
      min-width: 150px !important
      position: relative !important
    &.link

      z-index: 2
      max-width: 300px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      
      &:hover 
        overflow: visible
        padding: 0
        font-weight: 700
        &:not(.link_url)
          cursor: pointer
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: rgba(255,255,255,.8)
.datecell
  position: relative
  &__delete
    width: 20px
    background: rgba(255,255,255,.05)
    border: none
    position: absolute
    pointer-events: none
    opacity: 0
    bottom: 100%
    line-height: 0
    right: -11px
    z-index: 100
    background: rgba(0,0,0,.5)
    width: 17px
    height: 17px
    border-radius: 9999px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    font-size: 16px
    font-weight: 700
    color: red
    padding: 3px
    pointer-events: none
    transition: opacity .3s ease-out
    cursor: pointer
    opacity: 0
  &:hover &__input, &:focus-within &__input
    pointer-events: auto
    opacity: 1
  &__count
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    text-align: center
    width: auto
    min-width: 30px
    padding: 2px 8px
    position: absolute
    left: 50%
    transform: translateX(-50%)
    bottom: calc(100% + 5px)
    border: none
    background: white
    border: 2px solid violet
  &__input
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    width: 100px
    position: absolute
    right: 100%
    top: 0
    bottom: 0
    border: none
    background: white
    border: 2px solid black
    &_broken
      border: 2px solid red !important
  &:hover &__delete 
    opacity: 1
    pointer-events: auto
  &:hover &__delete:hover
    background: black
</style>