<template>
  <div class="w100">
    <div class="panel">
      <ProjectSelector />
      <ProjectBtn v-if="Role == 2" mode="create" />
      <ProjectBtn v-if="project && (Role == 2)" mode="delete" />
      <GroupSelector v-if="project" class="panel__gap" />
      <GroupBtn v-if="project && (Role == 2)" mode="create" />
      <!--<GroupBtn v-if="project && group && (Role == 2)" mode="edit" />-->
      <GroupBtn v-if="project && group && (Role == 2)" mode="delete" />
      <LogoutBtn />
      <Transition name="fade">
        <div class="panel__busy" tooltip="Сервер в данный момент выполняет обход сайтов" v-if="busy">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" :data-data="JSON.stringify($store.state.loads)">
            <circle cx="50" cy="50" r="20" stroke-width="8" stroke="#007bff" stroke-dasharray="31.41592653589793 31.41592653589793" fill="none" stroke-linecap="round">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
            </circle>
          </svg>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LogoutBtn from './buttons/logoutBtn.vue';
import GroupBtn from './buttons/GroupBtn.vue';
import GroupSelector from './GroupSelector.vue';
import ProjectSelector from './ProjectSelector.vue';
import ProjectBtn from './buttons/ProjectBtn.vue';
export default {
  computed: {
    ...mapState(["url", "Role", "group", "project", "busy"]),
  },
  components: { LogoutBtn, GroupSelector, GroupBtn, ProjectSelector, ProjectBtn }
}
</script>

<style lang="sass">
.panel
  display: flex
  gap: 10px
  &__busy
    margin-left: auto
    margin-right: 5px
    align-self: center
    svg
      display: block
      transform-origin: 50% 50%
      width: 30px
      transform: scale(3)
  &__right
    margin-left: auto
  &__gap
    margin-left: 20px
  select
    margin-right: 10px
  button
    font-weight: 700
    width: 40px
    height: 40px
    max-height: 40px
    padding: 0
    display: flex
    justify-content: center
    align-items: center
    line-height: 0
</style>