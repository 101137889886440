<template>
  <component :is="tag" class="input" :type="type" :value="modelValue" @input="$emit('update:modelValue',$event.target.value)" />
</template>

<script>
export default {
  props: {
    modelValue: { type: String },
    type: { type: String, default: 'text' },
    tag: { type: String, default: 'input' }
  },
}
</script>

<style lang="sass">
.input
  padding: 5px 10px
  border-radius: 5px
  background: white
  border: 1px solid rgba(0,0,0,.1)
  font-size: 16px
  color: black
  transition: .2s ease-out
  &::placeholder
    opacity: .5
  &.green
    background: colors.$ok
textarea.input
  height: 500px
  width: 100%
  padding: 5px
  resize: none
  white-space: pre
  font-size: 12px
  letter-spacing: -0.07em
</style>