<template>
  <Btn :title="tooltip" @click.stop="action" class="btn">
    <span v-if="mode == 'create'">+</span>
    <svg v-if="mode == 'delete'" stoke-width="55" viewBox="0 0 25 25">
      <g transform='translate(3.966 3.568)'>
        <path d='M0-.001l17.435 18.213M0 18.212L17.435 0' stroke-width='4.4' stroke-linecap='round'></path>
      </g>
    </svg>
  </Btn>
</template>

<script>
import { mapState } from 'vuex';
import Btn from './Btn.vue';
export default {
  props: {
    mode: String
  },
  components: {
    Btn,
  },
  methods: {
    action() {
      if (this.mode == 'create')
        return this.createProject()
      if (this.mode == 'delete')
        return this.deleteProject()
    },
    async createProject() {
      let name = prompt('Укажите имя проекта')
      this.$store.commit('toggleLoad', 'Projectbtn')
      await fetch(this.url + 'project', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
      }).then(() => {
        this.$store.dispatch('updateProjects')
      })
        .catch(err => { console.error(err); alert(err) })
      this.$store.commit('toggleLoad', 'Projectbtn')
    },
    async deleteProject() {
      if (!this.project?.name) return alert('Проект не указан')
      if (!confirm('Удалить проект ' + this.project?.name + '?'))
        return
      this.$store.commit('toggleLoad', 'Projectbtn')
      await fetch(this.url + 'project', {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: this.project?.name })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
      }).then(() => {
        this.$store.commit('setProject', null)
        this.$store.dispatch('updateProjects')
      })
        .catch(err => { console.error(err); alert(err) })
      this.$store.commit('toggleLoad', 'Projectbtn')
    },
  },
  computed: {
    ...mapState(['url', 'project']),
    tooltip() {
      if (this.mode == 'create') return 'Создать проект'
      if (this.mode == 'delete') return 'Удалить проект'
      return ''
    }
  }
}
</script>

<style lang="sass" scoped>
.btn
  font-size: 25px
  svg
    display: block
    width: 15px
</style>