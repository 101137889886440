import { createStore } from 'vuex'
//import LZUTF8 from 'lzutf8'

export default createStore({
  state: {
    busy: false,
    url: '',
    loads: {},
    project: null,
    group: null,
    projects: [],
    groups: [],
    reports: [],
    allusers: [],
    Role: null
  },
  mutations: {
    setBusy(state,busy) {
      state.busy = busy
    },
    setAllUsers(state, users) {
      state.allusers = users
    },
    setUrl(state, url) {
      state.url = url
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      state.Role = Role
    },
    setProjects(state, projects) {
      state.projects = projects
    },
    setProject(state, project) {
      state.project = project
    },
    setGroup(state, value) {
      state.group = value
    },
    setGroups(state, value) {
      state.groups = value
    },
    setReports(state, value) {
      state.reports = value
    },
  },
  actions: {
    reset({ commit }) {
      commit('setRole', false)
      commit('setReports', null)
      commit('setGroup', null)
      commit('setProject', null)
    },
    async checkAuth({ state, commit }) {
      commit('toggleLoad', 'checkAuth')
      await fetch(state.url + 'auth', { credentials: 'include', })
        .then(res => {
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role => {
          commit('setRole', role || false)
        }).catch(err => {
          console.error(err)
          commit('setRole', false)
        })
      commit('toggleLoad', 'checkAuth')
    },
    async updateAllUsers({ state, commit }) {
      commit('toggleLoad', 'updateAllUsers')
      await fetch(state.url + 'users', { credentials: 'include', })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setAllUsers', list)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateAllUsers')
    },
    async updateProjects({ state, commit, dispatch }) {
      commit('toggleLoad', 'updateProjects')

      await fetch(state.url + 'projects', { credentials: 'include', })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(async list => {
          commit('setProjects', list)
          if (state.project)
            dispatch('setProject', list.find(p => p == state.project?.name))
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateProjects')
    },
    async setProject({ state, commit }, name) {
      if (!name)
        return commit('setProject', null)
      commit('toggleLoad', 'setProject')
      await fetch(state.url + 'project' + `?name=${encodeURIComponent(name)}`, { credentials: 'include' })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(project => {
          commit('setProject', project)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'setProject')
    },
    async updateGroups({ state, commit, dispatch }) {
      if (!state.project) {
        commit('setGroup', null)
        dispatch('updateReports')
        return
      }
      commit('toggleLoad', 'updateGroups')
      await fetch(state.url + 'groups' + `?project=${encodeURIComponent(state.project?.name)}`, { credentials: 'include', })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setGroups', list)
          if (state.group)
            dispatch('setGroup', list.find(el => el.name == state.group.name))
          else
            dispatch('setGroup', null)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateGroups')
    },
    async setGroup({ state, commit, dispatch }, name) {
      if (!name) {
        commit('setGroup', null)
        dispatch('updateReports')
        return
      }
      commit('toggleLoad', 'setGroup')
      await fetch(state.url + 'group' + `?project=${encodeURIComponent(state.project?.name)}&group=${encodeURIComponent(name)}`, { credentials: 'include' })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(group => {
          commit('setGroup', group)
          dispatch('updateReports')
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'setGroup')
    },
    async updateReports({ state, commit }) {
      if (!state.project || !state.group)
        return commit('setReports', null)
      commit('toggleLoad', 'updateReports')
      await fetch(state.url + 'reports' + `?project=${encodeURIComponent(state.project?.name)}&group=${encodeURIComponent(state.group.name)}`, { credentials: 'include', })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(reports => {
          commit('setReports', reports)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateReports')
    },
  },
  getters: {
    loading(state) {
      return Object.keys(state.loads).length > 0
    },
    dates(state) {
      if (!state.reports)
        return []
      return state.reports.map(el=>el.date)
    }
  }
})
