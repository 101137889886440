<template>
  <select class="select" v-model="f">
    <option value=""></option>
    <option v-for="p in projects" :value="p" :key="p">{{ p }}</option>
  </select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['url', 'project', 'projects']),
    f: {
      get() {
        return this.project?.name || ''
      },
      set(v) {
        this.$store.dispatch('setProject', v)
      }
    }
  },
}
</script>

<style lang="sass">
</style>