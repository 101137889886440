<template>
  <div class="main">
    <TopPanel/>
    <div class="main__content">
      <div class="main__side">
        <ProjectData />
        <GroupData />
      </div>
      <div class="main__data">
        <ReportsWindow />
      </div>
    </div>
  </div>
</template>

<script>
import GroupData from '../components/GroupData.vue';
import ProjectData from '../components/ProjectData.vue';
import ReportsWindow from '../components/ReportsWindow.vue';
import TopPanel from '../components/TopPanel.vue';

export default {
  name: 'Main',
  components: { TopPanel, ProjectData, GroupData, ReportsWindow },
}
</script>
<style lang="sass">
.main
  input, textarea, select
    outline: none
    &:focus-visible
      border-color: black

  padding: 5px
  position: relative
  min-height: 100vh
  background: url('~@/assets/bg.webp')
  background-repeat: repeat
  display: flex
  flex-direction: column
  align-items: flex-start
  &::before
    content: ''
    position: absolute
    left:  0
    top: 0
    width: 100%
    height: 100%
    background: rgba(255,255,255,.5)   
    backdrop-filter: blur(2px)
  *
    position: relative
  &__content
    margin-top: 20px
    display: flex
    align-items: flex-start
    
  &__side
    min-width: 200px
    max-width: 300px
    display: flex
    flex-direction: column
    align-items: stretch
    gap: 30px
  &__viewer
    flex-grow: 1
  &__data
    align-self: stretch
    margin-left: 20px
    flex-grow: 1
</style>