<template>
  <select class="select" v-model="gr">
    <option value=""></option>
    <option v-for="g in groups" :value="g.name" :key="g.name">{{ g.name }}</option>
  </select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['url', 'group', 'groups', 'group']),
    gr: {
      get() {
        return this.group?.name
      },
      set(v) {
        this.$store.dispatch('setGroup', this.groups.find(el => el.name == v)?.name)
      }
    }
  },
}
</script>

<style lang="sass">
.select
  min-width: 200px
  font-size: 18px
  padding: 2px 5px
  border-radius: 5px
  align-self: stretch
  display: block
</style>